class RestApi {
    //用户数据示例
    customers = [
        {
            id: '3f99d89d-99c0-44f7-96c9-e4c6ff5ac4d2',
            name: 'Mattie',
            password: '123',
            avatar: 'https://uhome-cloud.oss-cn-shenzhen.aliyuncs.com/static/image/logo.png',
            email: 'Mattie@goeasy.io',
            phone: '138xxxxxxxx',
        },
        {
            id: '413985eb-c120-41ab-baea-93c3e751a14a',
            name: 'Wallace',
            password: '123',
            avatar: 'https://uhome-cloud.oss-cn-shenzhen.aliyuncs.com/static/image/logo.png',
            email: 'Wallace@goeasy.io',
            phone: '138xxxxxxxx',
        },
        {
            id: 'ce525fb0-99db-4e1a-bdac-1d09911cae7f',
            name: 'Tracy',
            password: '123',
            avatar: 'https://uhome-cloud.oss-cn-shenzhen.aliyuncs.com/static/image/logo.png',
            email: 'Tracy@goeasy.io',
            phone: '138xxxxxxxx',
        },
        {
            id: '04aa829b-02aa-4b28-85b8-0e113c37c61a',
            name: 'Juanita',
            password: '123',
            avatar: 'https://uhome-cloud.oss-cn-shenzhen.aliyuncs.com/static/image/logo.png',
            email: 'Juanita@goeasy.io',
            phone: '138xxxxxxx1',
        },
    ];
    // 店铺
    shops = [
        {
            name: '优家租房平台',
            avatar: 'https://uhome-cloud.oss-cn-shenzhen.aliyuncs.com/static/image/logo.png',
            id: 'shop01',
            goods: ['https://uhome-cloud.oss-cn-shenzhen.aliyuncs.com/static/image/logo.png', 'https://uhome-cloud.oss-cn-shenzhen.aliyuncs.com/static/image/logo.png', 'https://uhome-cloud.oss-cn-shenzhen.aliyuncs.com/static/image/logo.png',]
        },
        {
            name: '粒客租',
            avatar: 'https://uhome-cloud.oss-cn-shenzhen.aliyuncs.com/static/image/logo.png',
            id: 'shop02',
            goods: ['https://uhome-cloud.oss-cn-shenzhen.aliyuncs.com/static/image/logo.png', 'https://uhome-cloud.oss-cn-shenzhen.aliyuncs.com/static/image/logo.png', 'https://uhome-cloud.oss-cn-shenzhen.aliyuncs.com/static/image/logo.png',]
        },
        {
            name: '租赁熊',
            avatar: 'https://uhome-cloud.oss-cn-shenzhen.aliyuncs.com/static/image/logo.png',
            id: 'shop03',
            goods: ['https://uhome-cloud.oss-cn-shenzhen.aliyuncs.com/static/image/logo.png', 'https://uhome-cloud.oss-cn-shenzhen.aliyuncs.com/static/image/logo.png', 'https://uhome-cloud.oss-cn-shenzhen.aliyuncs.com/static/image/logo.png',]
        }
    ];
    // 客服代表
    agents = [
        {
            id: '6ec2c1df-24f4-4c33-a16c-d0c9de69ac09',
            name: 'Kim',
            password: '123',
            avatar: 'https://uhome-cloud.oss-cn-shenzhen.aliyuncs.com/static/image/logo.png',
            email: 'Kim@goeasy.io',
            phone: '138xxxxxxxx',
            shopId: 'shop01'
        },
        {
            id: '93f76849-6db8-47f1-a68f-6e597b45179a',
            name: 'Molly',
            password: '123',
            avatar: 'https://uhome-cloud.oss-cn-shenzhen.aliyuncs.com/static/image/logo.png',
            email: 'Molly@goeasy.io',
            phone: '138xxxxxxxx',
            shopId: 'shop01'
        },
        {
            id: '9c4ebaa6-e447-49cc-8091-34fdc25a3504',
            name: 'Harry',
            password: '123',
            avatar: 'https://uhome-cloud.oss-cn-shenzhen.aliyuncs.com/static/image/logo.png',
            email: 'Harry@goeasy.io',
            phone: '138xxxxxxxx',
            shopId: 'shop02'
        },
        {
            id: '4f0b5003-c592-4469-bbe9-22180faffe7c',
            name: 'Lara',
            password: '123',
            avatar: 'https://uhome-cloud.oss-cn-shenzhen.aliyuncs.com/static/image/logo.png',
            email: 'Lara@goeasy.io',
            phone: '138xxxxxxxx',
            shopId: 'shop02'
        },
        {
            id: 'f51f6655-be49-4813-87ad-353b9eee971f',
            name: 'Leo',
            password: '123',
            avatar: 'https://uhome-cloud.oss-cn-shenzhen.aliyuncs.com/static/image/logo.png',
            email: 'Leo@goeasy.io',
            phone: '138xxxxxxxx',
            shopId: 'shop03'
        },
        {
            id: '6c751842-545d-4da0-a203-49c31a78d202',
            name: 'Belle',
            password: '123',
            avatar: 'https://uhome-cloud.oss-cn-shenzhen.aliyuncs.com/static/image/logo.png',
            email: 'Belle@goeasy.io',
            phone: '138xxxxxxxx',
            shopId: 'shop03'
        }
    ];
    // 订单
    orders = [
        {
            id: '252364104325',
            url: 'https://uhome-cloud.oss-cn-shenzhen.aliyuncs.com/house/image/1596879737826.jpg',
            name: '上元岗大占角三巷4号（余式文）',
            price: '￥1299',
            count: 1
        },
        {
            id: '251662058022',
            url: 'https://uhome-cloud.oss-cn-shenzhen.aliyuncs.com/house/image/1596879747795.jpg',
            name: '上元岗大占角三巷5号（余式文）',
            price: '￥1100',
            count: 2
        },
        {
            id: '250676186141',
            url: 'https://uhome-cloud.oss-cn-shenzhen.aliyuncs.com/house/image/1596879759059.jpg',
            name: '上元岗大占角三巷6号（余式文）',
            price: '￥1399',
            count: 1
        }
    ];


    findShops() {
        return this.shops;
    }

    findCustomerById(customerId) {
        return this.customers.find((customer) => customer.id === customerId);
    }

    findCustomer(username, password) {
        return this.customers.find((customer) => customer.name === username && customer.password === password);
    }

    findAgents() {
        this.agents.forEach((agent) => {
            agent.shop = this.findShopById(agent.shopId);
        })
        return this.agents;
    }

    findShopById(shopId) {
        return this.shops.find((shop) => shop.id === shopId);
    }

    findAgent(username, password) {
        return this.agents.find((agent) => agent.name === username && agent.password === password);
    }

    findAgentById(agentId) {
        return this.agents.find((agent) => agent.id === agentId);
    }

    getOrderList() {
        return this.orders;
    }

    findCustomers() {
        return this.customers;
    }

}

export default new RestApi();
